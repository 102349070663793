import { combineReducers } from 'redux'

import auth from './slices/authSlice'
import users from './slices/usersSlice'
import theme from './slices/themeSlice'
import { 
    xncoSaleSites,
    xncoSaleMarkets, 
    xncoSaleCategories,
    xncoSaleCustomers,
    xncoSaleSalers,
    xncoSaleSalerStockAvailabilities,
    xncoSaleNewOnMarkets,
    xncoSaleProducts,
    xncoSaleDiscounts,
    xncoSaleDepartments,
    xncoSaleDiscountProducts,
    xncoSaleMarketReports,
    xncoSaleInvoices,
    xncoSaleInvoiceProducts,
    xncoSaleProductReturns
} from './slices/xncoSaleSlice'

import { 
    vesselClients,
    vesselDrivers,
    vesselPorts,
    vesselTasks,
    vesselVehicles,
    vesselShipSchedules,
    vesselShips,
    vesselPointers,
    vesselProducts,
    vesselTaskDetails
} from './slices/vesselSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        
        auth,

        users,

        xncoSaleSites,
        xncoSaleMarkets,
        xncoSaleCategories,
        xncoSaleCustomers,
        xncoSaleSalers,
        xncoSaleSalerStockAvailabilities,
        xncoSaleNewOnMarkets,
        xncoSaleProducts,
        xncoSaleDiscounts,
        xncoSaleDepartments,
        xncoSaleDiscountProducts,
        xncoSaleMarketReports,
        xncoSaleInvoices,
        xncoSaleInvoiceProducts,
        xncoSaleProductReturns,

        vesselClients,
        vesselDrivers,
        vesselPorts,
        vesselTasks,
        vesselVehicles,
        vesselShipSchedules,
        vesselShips,
        vesselPointers,
        vesselProducts,
        vesselTaskDetails,

        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
