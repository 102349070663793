import fetch from 'auth/FetchInterceptor';

const vesselService = {}

// Clients
// Fetch all clients
vesselService.getClients = async function (params, cb) {
    try {
    const res = await fetch({
      url: '/vessel/clients/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
  }
  
  // Add a new client
  vesselService.addClient = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/clients/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a client
  vesselService.updateClient = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/clients/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a client
  vesselService.deleteClient = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/clients/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Drivers
// Fetch all drivers
vesselService.getDrivers = async function (params, cb) {
  try {
    const res = await  fetch({
      url: '/vessel/drivers/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add a new driver
  vesselService.addDriver = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/drivers/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a driver
  vesselService.updateDriver = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/drivers/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a driver
  vesselService.deleteDriver = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/drivers/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Ports
// Fetch all ports
vesselService.getPorts = async function (params,cb) {
    try {
    const res = await fetch({
      url: '/vessel/ports/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add a new port
  vesselService.addPort = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/ports/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
    }
  }
  
  // Update a port
  vesselService.updatePort = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ports/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a port
  vesselService.deletePort = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ports/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Vehicles
// Fetch all vehicles
vesselService.getVehicles = async function (params, cb) {
  try {
    const res = await fetch({
      url: '/vessel/vehicles/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add a new vehicle
  vesselService.addVehicle = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/vehicles/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a vehicle
  vesselService.updateVehicle = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/vehicles/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a vehicle
  vesselService.deleteVehicle = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/vehicles/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Ship schedule
// Fetch all ship schedules
vesselService.getShipSchedules = async function (params,cb) {
    try {
    const res = await fetch({
      url: '/vessel/ship-schedules/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add a new ship schedule
  vesselService.addShipSchedule = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/ship-schedules/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a ship schedule
  vesselService.updateShipSchedule = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ship-schedules/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a ship schedule
  vesselService.deleteShipSchedule = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ship-schedules/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Get ship schedule by id
  vesselService.getShipScheduleById = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ship-schedules/${data.id}/`,
        method: 'get',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Ships
// Fetch all ships
vesselService.getShips = async function (params, cb) {
    try {
      const res = await fetch({
        url: '/vessel/ships/',
        method: 'get',
        params
      });
      if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add a new ship
  vesselService.addShip = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/ships/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a ship
  vesselService.updateShip = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ships/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a ship
  vesselService.deleteShip = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/ships/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Task Details
// Fetch all task details
vesselService.getTaskDetails = async function (params, cb) {
  try {
    const res = await fetch({
      url: '/vessel/task-details/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add new task detail
  vesselService.addTaskDetail = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/task-details/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a task detail
  vesselService.updateTaskDetail = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/task-details/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a task detail
  vesselService.deleteTaskDetail = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/task-details/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }

// Tasks
// Fetch all tasks
vesselService.getTasks = async function (params, cb) {
  try {
    const res = await  fetch({
      url: '/vessel/tasks/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add new task
  vesselService.addTask = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/tasks/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a task
  vesselService.updateTask= async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/tasks/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a task
  vesselService.deleteTask = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/task/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

// Pointers
// Fetch all pointers
vesselService.getPointers = async function (params, cb) {
  try {
    const res = await fetch({
      url: '/vessel/pointers/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
  }
  
  // Add a new pointer
  vesselService.addPointer = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/pointers/',
        method: 'post',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Update a pointer
  vesselService.updatePointer = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/pointers/${data.id}/`,
        method: 'put',
        data
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }
  
  // Delete a pointer
  vesselService.deletePointer = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/pointers/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

  // Products
  // Fetch all products
  vesselService.getProducts = async function (params, cb) {
    try {
      const res = await fetch({
        url: '/vessel/products/',
        method: 'get',
        params
      });
      if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
  }
  
  // Add a product
  vesselService.addProduct = async function (data, cb) {
    try {
      const res = await fetch({
        url: '/vessel/products/',
        method: 'post',
        data,
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

  // Update a product
  vesselService.updateProduct = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/products/${data.id}/`,
        method: 'put',
        data,
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }

  // Delete a product
  vesselService.deleteProduct = async function (data, cb) {
    try {
      const res = await fetch({
        url: `/vessel/products/${data.id}/`,
        method: 'delete',
      });
      if(cb) return cb(null, res);
        else return res
      } catch (err) {
        if(cb) return cb(err, null);
        else return err
      }
  }


export default vesselService