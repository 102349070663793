import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import XnCoSaleServices from 'services/XnCoSaleServices';

// Categories
export const categoriesInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	categories: [],
}

export const getCategories = createAsyncThunk('xncoSale/categories',async (data, { rejectWithValue }) => {
	try {
		const response = await XnCoSaleServices.getCategories(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const xncoSaleCategoriesSlice = createSlice({
	name: 'xncoSale-categories',
	initialState:categoriesInitialState,
	reducers: {
		showRequestMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideRequestMessage: state => {
			state.message = ''
			state.showMessage = false
		},
		showLoading: state => {
			state.loading = true
		}
	},
	extraReducers: builder => {
		builder.addCase(getCategories.pending, (state, action) => {
			state.loading = true
		})
		.addCase(getCategories.rejected, (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		})
		.addCase(getCategories.fulfilled, (state, action) => {
		  state.categories = action.payload
		  state.loading = false
		})
	},
});

// Markets
export const marketsInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	markets: [],
}

export const getMarkets = createAsyncThunk('xncoSale/markets',async (data, { rejectWithValue }) => {
	try {
		const response = await XnCoSaleServices.getMarkets(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const xncoSaleMarketsSlice = createSlice({
	name: 'xncoSale-markets',
	initialState:marketsInitialState,
	reducers: {
		showRequestMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideRequestMessage: state => {
			state.message = ''
			state.showMessage = false
		},
		showLoading: state => {
			state.loading = true
		}
	},
	extraReducers: builder => {
		builder.addCase(getMarkets.pending, (state, action) => {
			state.loading = true
		})
		.addCase(getMarkets.rejected, (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		})
		.addCase(getMarkets.fulfilled, (state, action) => {
		  state.markets = action.payload
		  state.loading = false
		})
	},
});

// Sites
export const sitesInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	sites: [],
}

export const getSites = createAsyncThunk('xncoSale/sites',async (data, { rejectWithValue }) => {
	try {
		const response = await XnCoSaleServices.getSites(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const xncoSaleSitesSlice = createSlice({
	name: 'xncoSale-sites',
	initialState:sitesInitialState,
	reducers: {
		showRequestMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideRequestMessage: state => {
			state.message = ''
			state.showMessage = false
		},
		showLoading: state => {
			state.loading = true
		}
	},
	extraReducers: builder => {
		builder.addCase(getSites.pending, (state, action) => {
			state.loading = true
		})
		.addCase(getSites.rejected, (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		})
		.addCase(getSites.fulfilled, (state, action) => {
		  state.sites = action.payload
		  state.loading = false
		})
	},
});

// Customers
export const customersInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	customers: [],
  }
  
  export const getCustomers = createAsyncThunk('xncoSale/customers', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getCustomers(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleCustomersSlice = createSlice({
	name: 'xncoSale-customers',
	initialState: customersInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getCustomers.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getCustomers.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getCustomers.fulfilled, (state, action) => {
		  state.customers = action.payload;
		  state.loading = false;
		});
	},
  });

//	Discounts
export const discountsInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	discounts: [],
  }
  
  export const getDiscounts = createAsyncThunk('xncoSale/discounts', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getDiscounts(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleDiscountsSlice = createSlice({
	name: 'xncoSale-discounts',
	initialState: discountsInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getDiscounts.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getDiscounts.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getDiscounts.fulfilled, (state, action) => {
		  state.discounts = action.payload;
		  state.loading = false;
		});
	},
  });

//   Invoices
export const invoicesInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	invoices: [],
  }
  
  export const getInvoices = createAsyncThunk('xncoSale/invoices', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getInvoices(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleInvoicesSlice = createSlice({
	name: 'xncoSale-invoices',
	initialState: invoicesInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getInvoices.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getInvoices.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getInvoices.fulfilled, (state, action) => {
		  state.invoices = action.payload;
		  state.loading = false;
		});
	},
  });

//   Products
export const productsInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	products: [],
  }
  
  export const getProducts = createAsyncThunk('xncoSale/products', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getProducts(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleProductsSlice = createSlice({
	name: 'xncoSale-products',
	initialState: productsInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getProducts.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getProducts.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getProducts.fulfilled, (state, action) => {
		  state.products = action.payload;
		  state.loading = false;
		});
	},
  });

//   Departments
export const departmentsInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	departments: [],
  }
  
  export const getDepartments = createAsyncThunk('xncoSale/departments', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getDepartments(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleDepartmentsSlice = createSlice({
	name: 'xncoSale-departments',
	initialState: departmentsInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getDepartments.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getDepartments.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getDepartments.fulfilled, (state, action) => {
		  state.departments = action.payload;
		  state.loading = false;
		});
	},
  });

//   Discount Products
export const discountProductsInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	discountProducts: [],
  }
  
  export const getDiscountProducts = createAsyncThunk('xncoSale/discountProducts', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getDiscountProducts(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleDiscountProductsSlice = createSlice({
	name: 'xncoSale-discountProducts',
	initialState: discountProductsInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getDiscountProducts.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getDiscountProducts.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getDiscountProducts.fulfilled, (state, action) => {
		  state.discountProducts = action.payload;
		  state.loading = false;
		});
	},
  });

//   Invoice Products
export const invoiceProductsInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	invoiceProducts: [],
  }
  
  export const getInvoiceProducts = createAsyncThunk('xncoSale/invoiceProducts', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getInvoiceProducts(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleInvoiceProductsSlice = createSlice({
	name: 'xncoSale-invoiceProducts',
	initialState: invoiceProductsInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getInvoiceProducts.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getInvoiceProducts.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getInvoiceProducts.fulfilled, (state, action) => {
		  state.invoiceProducts = action.payload;
		  state.loading = false;
		});
	},
  });

//   Saler Stock Availability
export const salerStockAvailabilitiesInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	salerStockAvailabilities: [],
  }
  
  export const getSalerStockAvailabilities = createAsyncThunk('xncoSale/salerStockAvailabilities', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getSalerStockAvailabilities(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleSalerStockAvailabilitiesSlice = createSlice({
	name: 'xncoSale-salerStockAvailabilities',
	initialState: salerStockAvailabilitiesInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getSalerStockAvailabilities.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getSalerStockAvailabilities.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getSalerStockAvailabilities.fulfilled, (state, action) => {
		  state.salerStockAvailabilities = action.payload;
		  state.loading = false;
		});
	},
  });

//   Saler
export const salersInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	salers: [],
  }
  
  export const getSalers = createAsyncThunk('xncoSale/salers', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getSalers(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleSalersSlice = createSlice({
	name: 'xncoSale-salers',
	initialState: salersInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getSalers.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getSalers.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getSalers.fulfilled, (state, action) => {
		  state.salers = action.payload;
		  state.loading = false;
		});
	},
  });

//   NewOnMarkets
export const newOnMarketInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	newOnMarket: [],
  }
  
  export const getNewOnMarket = createAsyncThunk('xncoSale/newOnMarket', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getNewOnMarket(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleNewOnMarketSlice = createSlice({
	name: 'xncoSale-newOnMarket',
	initialState: newOnMarketInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getNewOnMarket.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getNewOnMarket.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getNewOnMarket.fulfilled, (state, action) => {
		  state.newOnMarket = action.payload;
		  state.loading = false;
		});
	},
  });

//   ProductCategory
export const productCategoriesInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	productCategories: [],
  }
  
  export const getProductCategories = createAsyncThunk('xncoSale/productCategories', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getProductCategories(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleProductCategoriesSlice = createSlice({
	name: 'xncoSale-productCategories',
	initialState: productCategoriesInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getProductCategories.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getProductCategories.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getProductCategories.fulfilled, (state, action) => {
		  state.productCategories = action.payload;
		  state.loading = false;
		});
	},
  });

//   MarketReport
export const marketReportsInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	marketReports: [],
  }
  
  export const getMarketReports = createAsyncThunk('xncoSale/marketReports', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getMarketReports(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleMarketReportsSlice = createSlice({
	name: 'xncoSale-marketReports',
	initialState: marketReportsInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getMarketReports.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getMarketReports.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getMarketReports.fulfilled, (state, action) => {
		  state.marketReports = action.payload;
		  state.loading = false;
		});
	},
  });

//   ProductReturn
export const productReturnsInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	productReturns: [],
  }
  
  export const getProductReturns = createAsyncThunk('xncoSale/productReturns', async (data, { rejectWithValue }) => {
	try {
	  const response = await XnCoSaleServices.getProductReturns(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const xncoSaleProductReturnsSlice = createSlice({
	name: 'xncoSale-productReturns',
	initialState: productReturnsInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getProductReturns.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(getProductReturns.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getProductReturns.fulfilled, (state, action) => {
		  state.productReturns = action.payload;
		  state.loading = false;
		});
	},
  });

// export const {
// 	showRequestMessage,
// 	hideRequestMessage,
// 	showLoading,
// } = xncoSaleCategoriesSlice.actions

export const xncoSaleSites = xncoSaleSitesSlice.reducer
export const xncoSaleMarkets = xncoSaleMarketsSlice.reducer
export const xncoSaleCategories = xncoSaleCategoriesSlice.reducer
export const xncoSaleCustomers = xncoSaleCustomersSlice.reducer
export const xncoSaleSalers = xncoSaleSalersSlice.reducer
export const xncoSaleSalerStockAvailabilities = xncoSaleSalerStockAvailabilitiesSlice.reducer
export const xncoSaleNewOnMarkets = xncoSaleNewOnMarketSlice.reducer
export const xncoSaleProducts = xncoSaleProductsSlice.reducer
export const xncoSaleDiscounts = xncoSaleDiscountsSlice.reducer
export const xncoSaleDepartments = xncoSaleDepartmentsSlice.reducer
export const xncoSaleDiscountProducts = xncoSaleDiscountProductsSlice.reducer
export const xncoSaleMarketReports = xncoSaleMarketReportsSlice.reducer
export const xncoSaleInvoices = xncoSaleInvoicesSlice.reducer
export const xncoSaleInvoiceProducts = xncoSaleInvoiceProductsSlice.reducer
export const xncoSaleProductReturns = xncoSaleProductReturnsSlice.reducer