import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import UsersService from 'services/UsersService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	users: [],
	user: {},
}

export const getUsers = createAsyncThunk('users/allUsers',async (data, { rejectWithValue }) => {
	try {
		const response = await UsersService.getUsers(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateUser = createAsyncThunk('users/updateUser',async (data, { rejectWithValue }) => {
	try {
		const response = await UsersService.updateUser(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteUser = createAsyncThunk('users/deleteUser',async (data, { rejectWithValue }) => {
	try {
		const response = await UsersService.deleteUser(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		showRequestMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideRequestMessage: state => {
			state.message = ''
			state.showMessage = false
		},
		showLoading: state => {
			state.loading = true
		}
	},
	extraReducers: builder => {
		builder.addCase(getUsers.pending, (state, action) => {
			state.loading = true
		})
		.addCase(getUsers.rejected, (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		})
		.addCase(getUsers.fulfilled, (state, action) => {
		  state.users = action.payload
		  state.loading = false
		})
		.addCase(updateUser.pending, (state, action) => {

		})
		.addCase(updateUser.rejected, (state, action) => {
			
		})
		.addCase(updateUser.fulfilled, (state, action) => {
		  
		})
	},
})

export const {
	showRequestMessage,
	hideRequestMessage,
	showLoading,
} = usersSlice.actions

export default usersSlice.reducer
