import fetch from 'auth/FetchInterceptor';

const usersService = {}

usersService.getUsers = async function (params, cb) {
  try {
    const res = await fetch({
    url: '/accounts/users/',
    method: 'get',
  });
  cb(null,res)
  } catch (e) {
    cb(e,null)
  }
}

usersService.addUser = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/accounts/users/',
      method: 'post',
      data
    });
    cb(null,res)
  } catch (e) {
    cb(e,null)
  }
}

usersService.getUserById = async function (params, cb) {
  try {
    const res = await fetch({
      url: `/accounts/users/${params.id}/`,
      method: 'get',
      params
    });
    cb(null,res)
  } catch (e) {
    cb(e,null)
  }
}

usersService.updateUser = async function (data, cb) {
  try {
    const res = await fetch({
    url: `/accounts/users/${data.id}/`,
    method: 'put',
    data
  });
    return cb(null, res);
  } catch (e) {
    return cb(e, null);
  }
}

usersService.deleteUser = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/accounts/users/${data.id}/`,
      method: 'delete',
    });
    return cb(null, res);
  } catch (e) {
    return cb(e, null);
  }
}

export default usersService