import fetch from 'auth/FetchInterceptor';

const xncoSaleService = {}

// Categories
xncoSaleService.getCategories = function (params) {
  return fetch({
    url: '/sale/product-categories/',
    method: 'get',
  })
}

xncoSaleService.addCategory = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/product-categories/',
      method: 'post',
      data
    });
    cb(null,res)
  } catch (e) {
    cb(e,null)
  }
}

xncoSaleService.updateCategory = async function (data, cb) {
  try {
    const res = await fetch({
    url: `/sale/product-categories/${data.id}/`,
    method: 'put',
    data
  });
    return cb(null, res);
  } catch (e) {
    return cb(e, null);
  }
}

xncoSaleService.deleteCategory = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/product-categories/${data.id}/`,
      method: 'delete',
    });
    return cb(null, res);
  } catch (e) {
    return cb(e, null);
  }
}

// Sites
xncoSaleService.getSites = function (params) {
  return fetch({
    url: '/sale/sites/',
    method: 'get',
  })
}

xncoSaleService.addSite = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/sites/',
      method: 'post',
      data
    });
    cb(null,res)
  } catch (e) {
    cb(e,null)
  }
}

xncoSaleService.updateSite = async function (data, cb) {
  try {
    const res = await fetch({
    url: `/sale/sites/${data.id}/`,
    method: 'put',
    data
  });
    return cb(null, res);
  } catch (e) {
    return cb(e, null);
  }
}

xncoSaleService.deleteSite = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/sites/${data.id}/`,
      method: 'delete',
    });
    return cb(null, res);
  } catch (e) {
    return cb(e, null);
  }
}

// Markets
xncoSaleService.getMarkets = function (params) {
  return fetch({
    url: '/sale/markets/',
    method: 'get',
  })
}

xncoSaleService.addMarket = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/markets/',
      method: 'post',
      data
    });
    cb(null,res)
  } catch (e) {
    cb(e,null)
  }
}

xncoSaleService.updateMarket = async function (data, cb) {
  try {
    const res = await fetch({
    url: `/sale/markets/${data.id}/`,
    method: 'put',
    data
  });
    return cb(null, res);
  } catch (e) {
    return cb(e, null);
  }
}

xncoSaleService.deleteMarket = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/markets/${data.id}/`,
      method: 'delete',
    });
    return cb(null, res);
  } catch (e) {
    return cb(e, null);
  }
}

// Customers
xncoSaleService.getCustomers = function (params) {
  return fetch({
    url: '/sale/customers/',
    method: 'get',
  })
}

xncoSaleService.addCustomer = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/customers/',
      method: 'post',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.updateCustomer = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/customers/${data.id}/`,
      method: 'put',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.deleteCustomer = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/customers/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

// Discount Products
xncoSaleService.getDiscountProducts = function (params) {
  return fetch({
    url: '/sale/discount-products/',
    method: 'get',
  })
}

xncoSaleService.addDiscountProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/discount-products/',
      method: 'post',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.updateDiscountProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/discount-products/${data.id}/`,
      method: 'put',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.deleteDiscountProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/discount-products/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

// Invoices
xncoSaleService.getInvoices = function (params) {
  return fetch({
    url: '/sale/invoices/',
    method: 'get',
  })
}

xncoSaleService.addInvoice = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/invoices/',
      method: 'post',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.updateInvoice = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/invoices/${data.id}/`,
      method: 'put',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.deleteInvoice = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/invoices/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

// Saler Stock Availability
xncoSaleService.getSalerStockAvailabilities = function (params) {
  return fetch({
    url: '/sale/saler-stock-availabilities/',
    method: 'get',
  })
}

xncoSaleService.addSalerStockAvailability = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/saler-stock-availabilities/',
      method: 'post',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.updateSalerStockAvailability = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/saler-stock-availabilities/${data.id}/`,
      method: 'put',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.deleteSalerStockAvailability = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/saler-stock-availabilities/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

// Market Reports
xncoSaleService.getMarketReports = function (params) {
  return fetch({
    url: '/sale/market-reports/',
    method: 'get',
  })
}

xncoSaleService.addMarketReport = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/market-reports/',
      method: 'post',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.updateMarketReport = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/market-reports/${data.id}/`,
      method: 'put',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.deleteMarketReport = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/market-reports/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

// Product Returns
xncoSaleService.getProductReturns = function (params) {
  return fetch({
    url: '/sale/product-returns/',
    method: 'get',
  })
}

xncoSaleService.addProductReturn = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/product-returns/',
      method: 'post',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.updateProductReturn = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/product-returns/${data.id}/`,
      method: 'put',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.deleteProductReturn = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/product-returns/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

// Invoice Products
xncoSaleService.getInvoiceProducts = function (params) {
  return fetch({
    url: '/sale/invoice-products/',
    method: 'get',
  })
}

xncoSaleService.addInvoiceProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/invoice-products/',
      method: 'post',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.updateInvoiceProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/invoice-products/${data.id}/`,
      method: 'put',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.deleteInvoiceProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/invoice-products/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

// Salers
xncoSaleService.getSalers = function (params) {
  return fetch({
    url: '/sale/salers/',
    method: 'get',
  });
}

xncoSaleService.addSaler = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/salers/',
      method: 'post',
      data,
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.updateSaler = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/salers/${data.id}/`,
      method: 'put',
      data,
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.deleteSaler = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/salers/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

// Products
xncoSaleService.getProducts = function (params) {
  return fetch({
    url: '/sale/products/',
    method: 'get',
  });
}

xncoSaleService.addProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/products/',
      method: 'post',
      data,
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.updateProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/products/${data.id}/`,
      method: 'put',
      data,
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.deleteProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/products/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

// Discounts
xncoSaleService.getDiscounts = function (params) {
  return fetch({
    url: '/sale/discounts/',
    method: 'get',
  });
}

xncoSaleService.addDiscount = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/discounts/',
      method: 'post',
      data,
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.updateDiscount = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/discounts/${data.id}/`,
      method: 'put',
      data,
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.deleteDiscount = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/discounts/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

// NewOnMarkets
xncoSaleService.getNewOnMarket = function (params) {
  return fetch({
    url: '/sale/new-on-market/',
    method: 'get',
  });
}

xncoSaleService.addNewOnMarket = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/new-on-market/',
      method: 'post',
      data,
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.updateNewOnMarket = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/new-on-market/${data.id}/`,
      method: 'put',
      data,
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.deleteNewOnMarket = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/new-on-market/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

// Departments
xncoSaleService.getDepartments = function (params) {
  return fetch({
    url: '/sale/departments/',
    method: 'get',
  });
}

xncoSaleService.addDepartment = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/departments/',
      method: 'post',
      data,
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.updateDepartment = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/departments/${data.id}/`,
      method: 'put',
      data,
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

xncoSaleService.deleteDepartment = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/departments/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

export default xncoSaleService